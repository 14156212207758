<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <polyline
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      points="14.25 17.25 14.25 23.25 8.25 23.25"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="11.25"
      y1="20.25"
      x2="11.25"
      y2="23.25"
    />
    <polyline
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      points="8.25 17.85 8.25 23.25 2.25 23.25 2.25 18.75"
    />
    <polygon
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      points="7.5 18.75 11.25 14.25 4.5 14.25 0.75 18.75 7.5 18.75"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="15.75"
      y1="18.75"
      x2="11.25"
      y2="14.25"
    />
    <polygon
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      points="11.967 6.75 17.25 0.75 10.5 0.75 5.217 6.75 11.967 6.75"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="23.25"
      y1="6.75"
      x2="17.25"
      y2="0.75"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="12.75"
      y1="5.861"
      x2="12.75"
      y2="11.25"
    />
    <polyline
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      points="21.75 5.25 21.75 18.75 18.75 18.75"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="6.75"
      y1="11.25"
      x2="6.75"
      y2="6.75"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="15.75"
      y1="7.5"
      x2="15.75"
      y2="8.25"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="18.75"
      y1="7.5"
      x2="18.75"
      y2="8.25"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="15.75"
      y1="11.25"
      x2="15.75"
      y2="12"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="18.75"
      y1="11.25"
      x2="18.75"
      y2="12"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="18.75"
      y1="15"
      x2="18.75"
      y2="15.75"
    />
  </svg>
</template>
